export interface IUserProfileReqPayload {
  phoneNumber?: string;
  userTextNotificationsEnabled?: boolean;
  shouldDisplayFeedbackSurvey?: boolean; 
  shouldRemoveCalendar?: boolean;
  isDailyReminderEnabled?: boolean;
  isEveningReminderEnabled?: boolean;
  isProactiveWorkBlockCheckInReminderEnabled?: boolean;
  sessionId?: string;
  clientFlags?: number;
}

export interface IUserFeedbackReqPayload {
  feedbackText: string;
  contactEmail?: string;
}
export interface IUserSettingsFeedbackForm extends IUserFeedbackReqPayload {
  shouldSendEmail?: boolean;
}
export interface IUserFeedbackResponse {
  id: string;
  userId: string;
  feedbackText: string;
}

export interface IUpdatePreferredNameReqPayload{
  preferredName: string;
  sessionId: string;
}

// enum of bit values - should save as powers of 2
export enum EClientFlags {
  AUDIO_OFF = 1,
  DONT_SHOW_AGAIN_DISCORD_TOAST = 2,
  // FLAG_B = 4,
  // FLAG_C = 8,
  // FLAG_D = 16,
  // FLAG_E = 32,
}

export interface IResetUserReqPayload {
  isPartialReset: boolean;
}