import { useNavigate } from "react-router-dom";
import { LottieAppLoader } from "../../../shared/components/lottie-loader/LottieLoader"
import { useLocalStorage } from "../../../shared/utils/useLocalStorage";
import { APP_ROUTING_PATHS, loginTypeStorageKey, operatingSystemLocalStorageKey, refreshTokenLocalStorageKey, registrationParametersStorageKey, tokenLocalStorageKey, userInfoLocalStorageKey } from "../../constants";
import { useAppDispatch } from "../../store";
import { ELoginType, EOperatingSystem, IUser } from "../auth.interfaces";
import { useCallback, useEffect, useRef } from "react";
import { ApplicationInsightsApi, setAppInsightsUserContext } from "../../../application-insights";
import './MobileAppCheckLogin.scss';
import { getUserAppData } from "../../useAppData/user.store";
import { setAppModalContentType } from "../../../shared/components/app-modals/appModals.store";
import { EAppModalContentType } from "../../../shared/components/app-modals/appModals.interfaces";
import { registrationReqAction } from "../auth.store";

interface IProps {
    loginType: ELoginType;
    operatingSystem: EOperatingSystem;
}

export const MobileAppCheckLogin = ({ loginType, operatingSystem }: IProps) => {
    const [, setAccessToken] = useLocalStorage(tokenLocalStorageKey, '');
    const [, setRefreshToken] = useLocalStorage(refreshTokenLocalStorageKey, '');
    const [, setUserInfo] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
    const [, setLoginType] = useLocalStorage(loginTypeStorageKey, '');
    const [, setOperatingSystem] = useLocalStorage(operatingSystemLocalStorageKey, '');
    const isFetchingDataRef = useRef(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const extractMobileAppData = useCallback(() => {
        try {
            ApplicationInsightsApi.trackEvent(`Mobile App login - ${window.location.href}}`)
            setLoginType(loginType);
            setOperatingSystem(operatingSystem);
            const queryParams = new URLSearchParams(window.location.search);
            if (!queryParams.has('token') || !queryParams.has('refreshToken')) {
                navigate(APP_ROUTING_PATHS.LOGOUT);
                return;
            }
            const params: { [key: string]: string } = {};
            queryParams.forEach((value, key) => {
                if (key === 'token') {
                    setAccessToken(value);
                }
                else if (key === 'refreshToken') {
                    setRefreshToken(value);
                }
                else if (value) {
                    params[key] = value;
                }
            });
            if (Object.keys(params).length > 0) {
                localStorage.setItem(registrationParametersStorageKey, JSON.stringify(params));
            }
            dispatch(getUserAppData()).unwrap()
                .then(data => {
                    const { user } = data;
                    if (!!user) {
                        setUserInfo(user);
                        setAppInsightsUserContext();
                    }
                    if (!!data.releaseNotes) dispatch(setAppModalContentType(EAppModalContentType.RELEASE_NOTES));
                    if (!user?.isActive) {
                        dispatch(registrationReqAction({
                            registrationParameters: localStorage.getItem(registrationParametersStorageKey) || null
                        }));
                    }
                    else {
                        navigate(APP_ROUTING_PATHS.REDIRECT);
                    }
                })
                .catch((e) => {
                    ApplicationInsightsApi.trackException(e);
                    navigate(APP_ROUTING_PATHS.LOGOUT);
                });
        }
        catch (error) {
            ApplicationInsightsApi.trackException(error);
        }
    }, [setAccessToken, setRefreshToken, loginType, setLoginType, dispatch, setUserInfo, navigate, operatingSystem, setOperatingSystem]);

    useEffect(() => {
        if (!isFetchingDataRef.current) {
            isFetchingDataRef.current = true;
            extractMobileAppData();
        }
    }, [extractMobileAppData]);

    return (
        <div className="mobile-app-sso">
            <LottieAppLoader testId='lottie-sso-loader' />
        </div>
    )
}