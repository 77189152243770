import { TouchBackend } from 'react-dnd-touch-backend'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MouseTransition, TouchTransition } from 'react-dnd-multi-backend';

export const HTML5AndTouchBackends = {
    backends: [
      {
        backend: HTML5Backend,
        transition: MouseTransition,
        preview: true
      },
      {
        backend: TouchBackend,
        options: { 
            enableMouseEvents: true, // Enable mouse events to support both on touch devices
            enableDragPreview: true
          }, 
        transition: TouchTransition, // ensures that the TouchBackend is activated for touch interactions while still allowing mouse events (like on a touch-screen laptop).
        preview: true
      },
    ],
};