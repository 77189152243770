import { chatSessionIdSessionStorageKey } from "../../../../../app/constants";
import { store } from "../../../../../app/store";
import { getItemFromSessionOrLocalStorage } from "../../../../../shared/utils/getItemFromSessionOrLocalStorage";
import { IChatState } from "../../../chat.store";
import { ETaskStatus, IMessageDataTask } from "../stage-tasks/stageTasks.interface";
import { IStageTasksStore } from "../stage-tasks/stageTasks.store";
import { IWorkBlockFormData } from "./workBlock.interface";

export const getWorkBlockFormDefaultValues = (defaultWorkBlock: IMessageDataTask | null): IWorkBlockFormData => {
  const storeSessionId = (store.getState().chatReducer as IChatState).sessionId.data?.sessionId;
  const storeCalendarSelectedWorkTime = (store.getState().StageTasksReducer as IStageTasksStore).calendarSelectedWorkTime;
  const storeAllWorkBlock = (store.getState().StageTasksReducer as IStageTasksStore).allWorkBlocks;

  // if the defaultWorkBlock is recurrence instance get the values of the main instance
  if (!!defaultWorkBlock && defaultWorkBlock?.isRecurrenceInstance) {
    defaultWorkBlock = storeAllWorkBlock.find(w => w.id === defaultWorkBlock?.id && !w.isRecurrenceInstance) || null;
  }

  return (
    {
      sessionId: storeSessionId ||  getItemFromSessionOrLocalStorage(chatSessionIdSessionStorageKey) || '',
      status: ETaskStatus.NOT_STARTED,
      id: defaultWorkBlock?.id || '',
      name: !!defaultWorkBlock?.name ? defaultWorkBlock?.name : !!defaultWorkBlock ? 'Work Block' : '',
      workTime: defaultWorkBlock?.workTime || storeCalendarSelectedWorkTime,
      duration: defaultWorkBlock?.duration || 3*60*60, // the default workBlock duration is 3 hours (fits at least 2 tasks)
      workTimeReminder: defaultWorkBlock?.workTimeReminder || null,
      workTimeRecurrenceType: defaultWorkBlock?.workTimeRecurrenceType || null,
      workTimeRecurrenceInterval: defaultWorkBlock?.workTimeRecurrenceInterval || null,
      workTimeRecurrenceWeekDays: defaultWorkBlock?.workTimeRecurrenceWeekDays || null,
      isEvent: true,
      isWorkBlock: true
    }
  )
}