import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { chatSessionIdSessionStorageKey, loginTypeStorageKey, operatingSystemLocalStorageKey, tokenLocalStorageKey, userInfoLocalStorageKey } from '../../app/constants';
import { ApplicationInsightsApi } from '../../application-insights';
import { getItemFromSessionOrLocalStorage } from '../utils/getItemFromSessionOrLocalStorage';
import { IUser } from '../../app/auth/auth.interfaces';

/**
 * Add auth header with token, depending on server convention you might not need to add the word 'Bearer'
 * @param config axios config
 */
export const addAuthorizationHeaderInterceptor = (config: InternalAxiosRequestConfig) => {
    try {
        const token = getItemFromSessionOrLocalStorage<string>(tokenLocalStorageKey);
        const loginType = getItemFromSessionOrLocalStorage<string>(loginTypeStorageKey);
        const userId = (getItemFromSessionOrLocalStorage(userInfoLocalStorageKey) as IUser)?.id || null;
        const sessionId = getItemFromSessionOrLocalStorage(chatSessionIdSessionStorageKey) || null;
        const operatingSystem = getItemFromSessionOrLocalStorage<string>(operatingSystemLocalStorageKey) || null;

        if (config?.headers) {
            // if there is a token saved try to use it and set it in the headers in the request.
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
                config.headers['x-auth-provider'] = loginType;
            }
            if (userId) {
                config.headers['x-user-id'] = userId;
            }
            if (sessionId) {
                config.headers['x-session-id'] = sessionId;
            }
            if (operatingSystem) {
                config.headers['x-operating-system'] = operatingSystem;
            }
            config.headers['x-client-version'] = process.env.REACT_APP_BUILD_VERSION;
            config.headers['x-client-build-number'] = process.env.REACT_APP_BUILD_NUMBER;
        }
        return config;
    } catch (error) {
        ApplicationInsightsApi.trackException(error);
        console.error(error);
        return Promise.reject(error);
    }
};

export const addApplicationInsightsTraces = (config: InternalAxiosRequestConfig) => {
    try {
        const eventData = {
            requestBody: { ...config?.data },
            requestParams: { ...config?.params },
            userId: (getItemFromSessionOrLocalStorage(userInfoLocalStorageKey) as IUser)?.id || "userId_not_available",
            sessionId: getItemFromSessionOrLocalStorage(chatSessionIdSessionStorageKey) || "sessionId_not_available",
            baseURL: config?.baseURL,
        };
        ApplicationInsightsApi.trackEvent(`Client app - ${config?.method} - ${config?.url}`, eventData);
        return config;
    } catch (error) {
        ApplicationInsightsApi.trackException(error);
        return Promise.reject(error);
    }
};

export const rejectionApplicationInsightTraces = (error: any) => {
    try {
        const eventData = {
            error: { ...error },
            userId: (getItemFromSessionOrLocalStorage(userInfoLocalStorageKey) as IUser)?.id || "userId_not_available",
            sessionId: getItemFromSessionOrLocalStorage(chatSessionIdSessionStorageKey) || "sessionId_not_available",
        };
        ApplicationInsightsApi.trackEvent(`Client app error`, eventData);
        return Promise.reject(error);
    } catch (error) {
        ApplicationInsightsApi.trackException(error);
        return Promise.reject(error);
    }
};

export const updatePendingIndicationToWindowOnRequest = (config: InternalAxiosRequestConfig) => {
    try {
        (window as any).isAxiosPending = true;
        return config;
    } catch (error) {
        (window as any).isAxiosPending = false;
        return Promise.reject(error);
    }
}

export const updatePendingIndicationToWindowOnResponse = (response: AxiosResponse<any, any>) => {
    try {
        (window as any).isAxiosPending = false;
        return response;
    } catch (error) {
        (window as any).isAxiosPending = false;
        ApplicationInsightsApi.trackException(error);
        return Promise.reject(error);
    }
}

export const rejectionUpdatePendingIndicationToWindow = (error: any) => {
    try {
        (window as any).isAxiosPending = false;
        ApplicationInsightsApi.trackEvent(`Client app error`, error);
        return Promise.reject(error);
    } catch (error) {
        (window as any).isAxiosPending = false;
        return Promise.reject(error);
    }
}