import { usePreview } from 'react-dnd-multi-backend';
import { IDragItem } from '../../../features/plan/calendar/calendar-day/CalendarEvent';
import { ConnectableElement, DragPreviewOptions } from 'react-dnd';
import { ReactElement, useEffect, useRef } from 'react';

export const DraggedDestinationPreview = () => {
  const preview = usePreview();

  if (!preview.display) {
    return null
  }

  const { item, style } = preview;
  const previewStyle = (item as IDragItem)?.eventStyle || {};

  return (
    <div style={{ ...style, ...previewStyle }} />
  );
}


// The custom preview using usePreview() (inside DraggedDestinationPreview component) is displayed together with the default preview in desktop.
// This component return a transparent element for  on desktop so the custom preview is displayed alone
export const TransparentDefaultPreview = ({ dragPreview }: ITransparentDefaultPreview) => {
  const transparentRef = useRef(null);

  useEffect(() => {
    if (transparentRef.current) {
      dragPreview(transparentRef.current);
    }
  }, [dragPreview]);

  return (
    <div
      ref={transparentRef}
      style={{
        position: 'fixed',
        top: -9999,
        left: -9999,
        width: 0,
        height: 0,
        opacity: 0,
      }}
    />
  )
}

interface ITransparentDefaultPreview {
  dragPreview: (elementOrNode: ConnectableElement, options?: DragPreviewOptions | undefined) => ReactElement | null
}