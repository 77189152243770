import { FunctionComponent } from 'react';
import { Chat } from '../chat/Chat';
import { useSearchParams } from 'react-router-dom';
import { stageParam } from '../../../app/constants';
import { BackgroundAnimation } from '../chat/background-animation/BackgroundAnimation';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { EAPIStatus } from '../../../shared/api/models';
import StageContainer from './stage-container/StageContainer';
import { Navbar } from './navbar/Navbar';
import { useApiData } from '../../../shared/hooks/useApiData';
import { getTasksListReqAction } from './stage-container/stage-tasks/stageTasks.store';
import { isDesktopView } from '../../../shared/utils/utils';
import { EAppStageContentType } from './stage-container/stageContainer.interfaces';
import './ResizableContainer.scss';

export const ResizableContainer: FunctionComponent = () => {
  const [searchParams,] = useSearchParams();
  const { botResponse, sessionId } = useAppSelector(store => store.chatReducer);
  const { shouldStageExpand } = useAppSelector(store => store.StageTasksReducer);
  const dispatch = useAppDispatch();

  useApiData(sessionId, {
    onFulfilled: () => {
      dispatch(getTasksListReqAction());
    }
  });

  const containerClassName = () => {
    let className = "resizable-container";
    if (!!searchParams.get(stageParam)) {
      className += " display-stage";
      if (shouldStageExpand) className += " stage-expanded";
      if (searchParams.get(stageParam) === EAppStageContentType.TASKS) className += " task-list";
      else if (searchParams.get(stageParam) === EAppStageContentType.SCRATCHPAD) className += " scratchpad";
      else if (searchParams.get(stageParam) === EAppStageContentType.PLANNER) className += " planner";
    }
    return className;
  }

  return (
    <div className={containerClassName()}>
      <BackgroundAnimation className={`${botResponse.status === EAPIStatus.PENDING ? 'fadeIn' : 'fadeOut'}`} />
      <div className="flex-chat-stage-container">
        <Chat />
        {isDesktopView() && !shouldStageExpand && <StageContainer />}
      </div>
      <Navbar />
    </div>
  );
}