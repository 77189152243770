import { CSSProperties, FunctionComponent, ReactNode, RefObject, useRef } from "react";
import { isMobileDevice } from "../../../../shared/utils/isMobileDevice";
import useLongPress from "../../../../shared/hooks/useLongPress";
import { useDrop } from "react-dnd";
import { EDragAndDropType } from "../../../../shared/utils/utils";
import { IDragItem } from "./CalendarEvent";

interface ICalendarHourLineElement {
  className: string;
  keyComponent: number;
  style: CSSProperties;
  children?: ReactNode;
  handleClickOnHourLine: (hours: number, minutes: number) => void;
  lineIndex: number;
  isHalfHour?: boolean;
  calendarEventDrop: (dargItem: IDragItem, hours: number, minutes: number) => void;
  onOpenCreateOverlay: (hours: number, minutes: number) => void;
  scrollableContainerRef: RefObject<HTMLDivElement>
}

const CalendarHourLineElement: FunctionComponent<ICalendarHourLineElement> = ({
  className,
  keyComponent,
  style,
  children,
  handleClickOnHourLine,
  lineIndex,
  calendarEventDrop,
  onOpenCreateOverlay,
  scrollableContainerRef,
  isHalfHour = false
}) => {
  const longPressBind = useLongPress();
  const elementRef = useRef<HTMLDivElement | null>();

  const [{ isDraggableItemOver }, dropRef] = useDrop({
    accept: EDragAndDropType.CALENDAR_EVENT,
    drop: (item: IDragItem) => calendarEventDrop(item, lineIndex % 24, isHalfHour ? 30 : 0),
    collect: (monitor) => ({
      isDraggableItemOver: monitor.isOver(),
    }),
    hover: (_, monitor) => {
      // Auto-scroll the scrollable container when dragging near the top/bottom
      const SCROLL_THRESHOLD = 50; // Distance from the top/bottom to trigger scrolling
      const SCROLL_SPEED = 6; // Speed of the scrolling
      const clientOffset = monitor.getClientOffset();
      const scrollableContainer = scrollableContainerRef.current;
      if(!scrollableContainer) return;
      const boundingRect = scrollableContainer.getBoundingClientRect();

      if (clientOffset) {
        const topDistance = clientOffset.y - boundingRect.top;
        const bottomDistance = boundingRect.bottom - clientOffset.y;

        // Auto-scroll up if near the top
        if (topDistance < SCROLL_THRESHOLD) {
          scrollableContainer.scrollTop -= SCROLL_SPEED;
        }

        // Auto-scroll down if near the bottom
        if (bottomDistance < SCROLL_THRESHOLD) {
          scrollableContainer.scrollTop += SCROLL_SPEED;
        }
      }
    },
  });

  return (
    <div
      key={keyComponent}
      ref={(node) => {
        dropRef(node);
        elementRef.current = node;
      }}
      className={`${className} ${isDraggableItemOver ? 'draggable-item-is-over' : ''}`}
      style={{ ...style }}
      onClick={() => handleClickOnHourLine(lineIndex % 24, isHalfHour ? 30 : 0)}
      onDoubleClick={() => !isMobileDevice() && onOpenCreateOverlay(lineIndex % 24, isHalfHour ? 30 : 0)}
      {...longPressBind(() => onOpenCreateOverlay(lineIndex % 24, isHalfHour ? 30 : 0))}
    >
      {children}
    </div>
  )
}

export default CalendarHourLineElement