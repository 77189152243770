import React, { Fragment, FunctionComponent, SetStateAction } from 'react'
import { ETaskStatus, IAddEditTaskForm, IMessageDataTask, ISubTask } from '../../stageTasks.interface';
import { uuid } from '../../../../../../../shared/utils/uuid';
import SingleTaskItemContainer from '../../../../../chat/chat-conversation/message-data-card/tasks-list/tasks-list-single-item/SingleTaskItemContainer';
import { useTranslation } from 'react-i18next';
import { FieldArrayMethodProps, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/store';
import { createTaskReqAction, getTasksListReqAction } from '../../stageTasks.store';
import { useLocalStorage } from '../../../../../../../shared/utils/useLocalStorage';
import { chatSessionIdSessionStorageKey } from '../../../../../../../app/constants';
import { ISubTaskForEditing } from '../CreateOrEditTask';
import { handleInputKeyDown } from '../../../../../../../shared/utils/utils';
import './SubtasksSection.scss';
import { uxEventAddTaskOrSubTask } from '../../../../../../../shared/utils/uxEventsUtils';
import { taskFormToTaskEntity } from '../CreateOrEditTask.utils';
import { setQueueMessageData } from '../../../../../chat.store';
import { setClickNavbarConfirmModalText } from '../../../../../../../shared/store/shared.store';
import { ReactComponent as AddSubTaskIconComponent } from '../../../../../../../assets/images/add-sub-task-icon.svg';

interface ISubtasksSectionProps {
  subtasksList: ISubTask[];
  setSubtaskForEditing: (value: SetStateAction<ISubTaskForEditing | null>) => void
  updateSubtask: (index: number, updatedTask: ISubTask) => void
  appendNewSubtask: (value: ISubTask | ISubTask[], options?: FieldArrayMethodProps | undefined) => void;
  onRemoveSubTaskFromParentFieldArray: UseFieldArrayRemove;
  isEditModeOfExistingTask: boolean;
  parentId?: string;
}

const SubtasksSection: FunctionComponent<ISubtasksSectionProps> = ({ subtasksList, setSubtaskForEditing, updateSubtask, appendNewSubtask, onRemoveSubTaskFromParentFieldArray, isEditModeOfExistingTask, parentId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { sessionId } = useAppSelector(store => store.chatReducer);
  const [sessionIdLocalStorage,] = useLocalStorage(chatSessionIdSessionStorageKey, '');
  const taskForm = useFormContext<IAddEditTaskForm>();

  const onAddNewSubtask = (stepName: string) => {
    if(!isEditModeOfExistingTask) dispatch(setClickNavbarConfirmModalText(t("newTaskWillNotBeSavedNavbarConfirm")));
    if (stepName.length > 0) {
      const newSubTaskId = uuid();
      appendNewSubtask({
        taskId: newSubTaskId,
        name: stepName,
        dueDate: null,
        duration: null,
        workTime: null,
        status: ETaskStatus.NOT_STARTED,
        reminderTime: null,
        priority: false,
        quickWin: false,
        description: null,
        notes: null,
      })
      // if the parent is exist append call createTaskReqAction to create a new subtask and update the field array subtask with the response
      if (isEditModeOfExistingTask) {
        const currentSessionId = sessionId?.data?.sessionId || sessionIdLocalStorage;
        const reqPayload = { id: newSubTaskId, sessionId: currentSessionId, parentId: parentId, name: stepName, status: ETaskStatus.NOT_STARTED }
        dispatch(createTaskReqAction(reqPayload)).unwrap()
          .then((stepData) => {
            if (stepData.length > 0) {
              const subTask = stepData[0];
              updateSubtask(subtasksList.length, { ...subTask, taskId: subTask.id });
              const parentTask = taskFormToTaskEntity(taskForm, null);
              parentTask.id = parentId;
              const siblings = subtasksList.map((_,i) => taskFormToTaskEntity(taskForm, null, i)).filter(t => t.id !== subTask.id);
              dispatch(setQueueMessageData({ type: 'automatic', botRequestJson: JSON.stringify(uxEventAddTaskOrSubTask(parentTask, subTask, siblings))}));
            }
            dispatch(getTasksListReqAction());
          })
          .catch(() => {
            // if failed - remove the current subtask from the local field array
            onRemoveSubTaskFromParentFieldArray(subtasksList.length);
          })
      }
    }
  }

  return (
    <section className='subtasks-container task-detail-section'>
      <h3 className='create-or-edit-sub-title'>{t("addEditTaskSubtasksTitle")}</h3>
      {subtasksList.map((subtask: ISubTask, i) =>
        <Fragment key={uuid()}>
          <SingleTaskItemContainer
            shouldHideOnComplete={false}
            shouldDisplayFirstTagColor={true}
            singleTask={{ ...subtask, id: subtask?.taskId, siblingsData: { subTaskOrder: i + 1, totalSiblings: subtasksList.length } } as IMessageDataTask}
            onClick={() => setSubtaskForEditing({ ...subtask, parentId: parentId, indexInParentStepsArr: i })}
            onUpdate={(updateTask) => updateSubtask(i, { ...updateTask, taskId: updateTask.id })} />
        </Fragment>
      )}
      <div className='add-subtask-input-container'>
        <AddSubTaskIconComponent className={`scheduling-icon`} />
        <input
          type="text"
          id="add-subtask"
          minLength={1}
          placeholder={t("addEditTaskAddSubtasksInputPlaceholder")}
          onBlur={(e) => {
            onAddNewSubtask(e.target?.value);
            if (e.target?.value) e.target.value = ''
          }}
          onKeyDown={handleInputKeyDown}
        />
      </div>
    </section>
  )
}

export default SubtasksSection
