import { ApplicationInsightsApi } from "./application-insights";

export interface IMobileAppRefreshTokenMessageData {
    token: string,
    refreshToken: string,
}


// mobile app webview injects messageHandler to the window object
// on ios devices, the handler is on window.webkit.messageHandlers.messageHandler
const sendMessageToMobileApp = (message: string) => {
    try {
        const currentWindow = window as any;
        if (!currentWindow || !currentWindow.messageHandler || !currentWindow.webkit.messageHandlers) {
            return;
        }
        ApplicationInsightsApi.trackEvent("sendMessageToMobileApp - sending a message to the mobile app", { message });
        if (currentWindow.messageHandler) {
            currentWindow.messageHandler.postMessage(message);
        }
        else if (currentWindow.webkit && currentWindow.webkit.messageHandlers && currentWindow.webkit.messageHandlers.messageHandler) {
            currentWindow.webkit.messageHandler.messageHandler.postMessage(message);
        }
    }
    catch (e) {
        ApplicationInsightsApi.trackException(e);
    }
}

export const sendRefreshTokenToMobileApp = (data: IMobileAppRefreshTokenMessageData) => {
    sendMessageToMobileApp(JSON.stringify(data));
}

export const isMobileAppWebView = (): boolean => {
    const currentWindow = window as any;
    if (!!currentWindow?.messageHandler || !!currentWindow?.webkit?.messageHandlers?.messageHandler) {
        return true;
    }
    return false;
}