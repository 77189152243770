import { noRedirectLocalStorageKey } from "../../app/auth/auth.utils";
import { plannerModeOnLocalStorageKey, recentTaskTagsLocalStorageKey, selectedFilterTypeLocalStorageKey, shouldShowDiscordToastInCurrentSessionKey, turnsCounterStorageKey } from "../../app/constants";
import { clearSearchParamsFromCurrentUrl } from "./routingUtils";

export const handleLogOut = (shouldReload=true) => {
  sessionStorage.clear();
  Object.keys(localStorage).forEach(key => {
    if (![recentTaskTagsLocalStorageKey, noRedirectLocalStorageKey, selectedFilterTypeLocalStorageKey, plannerModeOnLocalStorageKey, turnsCounterStorageKey, shouldShowDiscordToastInCurrentSessionKey].includes(key)) {
      localStorage.removeItem(key);
    }
  });
  clearSearchParamsFromCurrentUrl();
  if(shouldReload) window.location.reload();
}