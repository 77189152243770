import React, { FunctionComponent } from 'react'
import './TaskActionButton.scss';
import { isMobileDevice } from '../../../../../../../../shared/utils/isMobileDevice';
import AppButton from '../../../../../../../../shared/components/app-button/AppButton';

interface ITaskActionButtonProps {
  actionName: string;
  onClickAction: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  actionType: 'due' | 'later' | 'schedule' | 'delete';
  className?:string
}

const TaskActionButton:FunctionComponent<ITaskActionButtonProps> = ({actionName,onClickAction,actionType,className=''}) => {
  const isMobile = isMobileDevice();

  return (
    <AppButton type="button" className={`task-action-button task-action-button--${actionType} ${className} ${isMobile ? 'task-action-button--mobile-view' : ''}`} onClick={onClickAction} id={`${actionType}-task-quick-action-setter`}>
      {actionName}
    </AppButton>
  )
}

export default TaskActionButton;
