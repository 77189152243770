import { automaticBotNotificationEvents } from "../../../../../../app/constants";
import { getTimeByDate } from "../../../../../../shared/utils/dateFormat";
import { IHumanStudentTurnSendInputPayload } from "../../../../chat.interfaces";
import { ETaskStatus, ETasksGroupsType, IMessageDataTask } from "../../../../resizable-container/stage-container/stage-tasks/stageTasks.interface";
import sassVariables from '../../../../../../styles/style.module.scss';

export const unscheduledSorting = (a: IMessageDataTask, b: IMessageDataTask) => {
  // Sorted by due date ascending (for tasks that have a due date) then by creation date, descending (for tasks w/o due date)
  // Tasks with a due date are sorted ascendingly by dueDate
  if (a.dueDate && b.dueDate) {
    return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
  }
  // Tasks without a due date are sorted descendingly by creationDate
  if (!a.dueDate && !b.dueDate) {
    return new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime();
  }
  // A task with a dueDate comes before a task without a dueDate
  if (a.dueDate) return -1;
  if (b.dueDate) return 1;
  return 0;
}

// sort first the tasks that dont include workBlockOrder and then the rest by workBlockOrder ascending
export const sortingTasksByWorkBlockOrderAscending = (a: IMessageDataTask, b: IMessageDataTask): number => { 
  const isOrderExist_A = !!a.workBlockOrder || a.workBlockOrder === 0;
  const isOrderExist_B = !!b.workBlockOrder || b.workBlockOrder === 0;
  if(!isOrderExist_A && !isOrderExist_B) return 0;
  if(!isOrderExist_A) return 1;
  if(!isOrderExist_B) return -1;
  return a.workBlockOrder! - b.workBlockOrder!;
}

export const getTasksGroupEmoji = (groupType: ETasksGroupsType): string => {
  switch (groupType) {
      case ETasksGroupsType.MY_TASKS:
          return "📓 ";
      case ETasksGroupsType.OVERDUE:
          return "🚩 ";
      case ETasksGroupsType.UNSCHEDULED:
          return "🗓 ";
      case ETasksGroupsType.DONE:
          return "✔ ";
      case ETasksGroupsType.SCHEDULED:
          return "🚀 ";
      case ETasksGroupsType.PRIORITY:
          return "⭐ ";
      case ETasksGroupsType.QUICK_WINS:
          return "⚡ ";
      case ETasksGroupsType.MY_PLAN:
          return "🙌 ";
      case ETasksGroupsType.MY_DAY:
          return "☀️ ";
      case ETasksGroupsType.MY_WEEK:
          return "📅 ";
      default:
          return "";
  }
}

// order tasks by groups shared function
export const orderTasksListByGroups = (tasksList: IMessageDataTask[]): {[key: string]: IMessageDataTask[]} => {
  return tasksList.reduce((accumulator: { [key: string]: IMessageDataTask[] }, current: IMessageDataTask) => {
      let currentGroupName:ETasksGroupsType = ETasksGroupsType.SCHEDULED;
      if (current.status === ETaskStatus.DELETED) return accumulator;
      if (current.isEvent) currentGroupName = ETasksGroupsType.EVENT;
      else if(current.status === ETaskStatus.SCRATCHPAD) currentGroupName = ETasksGroupsType.SCRATCHPAD;
      else if(current.status === ETaskStatus.DONE) currentGroupName = ETasksGroupsType.DONE;
      else if(current.priority) currentGroupName = ETasksGroupsType.PRIORITY;
      else if(current.quickWin) currentGroupName = ETasksGroupsType.QUICK_WINS;
      else if(current?.dueDate && isOverdueDate(current?.dueDate)) currentGroupName = ETasksGroupsType.OVERDUE;
      else if(!current?.workTime && !current?.workBlockId) currentGroupName = ETasksGroupsType.UNSCHEDULED;
      accumulator[currentGroupName] ? accumulator[currentGroupName].push(current) : accumulator[currentGroupName] = [current]
      return accumulator;
  }, {[ETasksGroupsType.OVERDUE]: [],[ETasksGroupsType.SCHEDULED]: [], [ETasksGroupsType.UNSCHEDULED]: [], [ETasksGroupsType.DONE]: [],[ETasksGroupsType.QUICK_WINS]: [],[ETasksGroupsType.PRIORITY]: [], [ETasksGroupsType.SCRATCHPAD]: [],[ETasksGroupsType.EVENT]: []});
}

export const isOverdueDate = (date: string|Date):boolean => new Date(date) < new Date();

export const shouldDisplayTaskOnTasksFilters = (task: IMessageDataTask):boolean => !task.isEvent && ![ETaskStatus.DONE, ETaskStatus.SCRATCHPAD].includes(task.status);

// TODO move to a class handling all automated bot events
export const createAutomatedTaskEvent = (sessionId: string, field: string, value: any, task: IMessageDataTask): IHumanStudentTurnSendInputPayload => {
  return {
      sessionId,
      option: {
          text: "",
          tool: {
            name: automaticBotNotificationEvents.UX_SET_TASK_FIELD,
            arg: {
              field,
              value,
              task
            }
          }
      }
  }
}
// generates time range from date and duration (in seconds) in the following format: "HH:mm - HH:mm AM/PM"
// when hour12 is true the following format will be with a 12-hour module: "HH:mmAM/PM - HH:mmAM/PM"
export const getTimeRange = (date: Date, duration: number | null | undefined, hour12?:boolean): string => {
  // if there is no duration or duration is 0 return time of date in the following format: "HH:mm AM/PM"
  if (!duration || duration === 0) return date.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'});
  const startDate = new Date(date);
  const endDate = new Date(startDate.getTime() + duration * 1000);
  // when hour12 is true the following format will be with a 12-hour module: "HH:mmAM/PM - HH:mmAM/PM"
  if(hour12) {
    return `${getTimeByDate(startDate)} - ${getTimeByDate(endDate)}`;
  }
  return `${startDate.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'}).replace('AM', '').replace('PM', '')} - ${endDate.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})}`;
}

// returns the date formatted as Ddd Mmm dd
export const getDateFormatted = (date: Date): string => {
  return date.toDateString().slice(0, 10);
}

export const getFirstLabelColor = (task: IMessageDataTask) => {
  if (task.tags && task.tags.length > 0) {
    if (!task.tags[0]?.color || task.tags[0].color === 'transparent') return sassVariables.Neutral1Color;
    else return task.tags[0]?.color;
  }
  return sassVariables.Neutral1Color;
}

export const deleteTaskModalAppOverlayPosition = () => { 
  const stagingElement = document.getElementById("stage-container-main");
  const appOverlayWidth = Number(sassVariables.appOverlayCardWidthDesktop) || 0;
  let stagingCenter = 50;

  // position the delete confirm modal at the center of the stage element
  if(stagingElement) {
    const rect = stagingElement.getBoundingClientRect();
    stagingCenter = (rect.left + rect.width / 2) - (appOverlayWidth/2) + 4;
  } else {
    // if stagingElement not exist position the modal at the center od the window
    stagingCenter = window.innerWidth / 2 - (appOverlayWidth/3);
  }

  return {
    left:`${stagingCenter}px`,
    right: `unset`,
    top: sassVariables.deleteTaskConfirmModalTopPosition,
  }
}