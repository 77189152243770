import { createAction, createReducer } from "@reduxjs/toolkit";
import { ApiDataStateType, createAPIReducerCases } from "../../../../../shared/store/utils";
import { apiServiceCSharp, createApiThunk } from "../../../../../shared/api/axios";
import { ETaskFormType, ETasksGroupsType, ICreateTaskReqPayload, IMessageDataTask, IStageTasksListResponse, ITaskUpdateReqPayload } from "./stageTasks.interface";
import { API_ROUTES, didUserCreateOrEditTaskStorageKey, fullDayDurationInSeconds, selectedFilterTypeLocalStorageKey } from "../../../../../app/constants";
import { APIRequestState, IAPIRequestState } from "../../../../../shared/api/models";
import { store } from "../../../../../app/store";
import { orderTasksListByGroups } from "../../../chat/chat-conversation/message-data-card/tasks-list/TasksList.utils";

export interface IStageTasksStore extends ApiDataStateType {
  tasksListResponse: IAPIRequestState<IStageTasksListResponse>,
  tasksFilter: ETasksGroupsType,
  updateTaskRes: IAPIRequestState<IMessageDataTask>,
  shouldStageExpand: boolean,
  selectedMainTaskForEditing: IMessageDataTask | null,
  shouldOpenAddEditTaskFrom: ETaskFormType | null,
  createTaskRes: IAPIRequestState<IMessageDataTask[]>,
  tasksOrderedByGroups: {[key: string]: IMessageDataTask[]} | null;
  shouldStartDestroyAnimationTaskForm: boolean;
  allTasks: IMessageDataTask[];
  allWorkBlocks: IMessageDataTask[];
  activeMyPlanDay: string | null;
  calendarSelectedWorkTime: string | null;
  updatedTasksViaTheChat: IMessageDataTask[] | null;
}

const initialStateStageTasksStore: IStageTasksStore = {
  tasksListResponse: APIRequestState.create(),
  tasksFilter: ETasksGroupsType.MY_TASKS,
  updateTaskRes: APIRequestState.create(),
  shouldStageExpand: false,
  selectedMainTaskForEditing: null,
  shouldOpenAddEditTaskFrom: null,
  createTaskRes: APIRequestState.create(),
  tasksOrderedByGroups: null,
  shouldStartDestroyAnimationTaskForm: false,
  allTasks: [],
  allWorkBlocks: [],
  activeMyPlanDay: null,
  calendarSelectedWorkTime: null,
  updatedTasksViaTheChat: null
};

const createReducerKey = (subKey: string): string => {
  return 'stageTasks/' + subKey;
};
// If a task has no name and has a parentId, then it should inherit the name of the parent task
export const updateSubTaskNameAndSiblingsData = (task: IMessageDataTask, currentTaskList: IMessageDataTask[]) => {
  if (((!task.name || task.name.length === 0) && !!task.parentId) || task.isMultiWorkTimeTask) {
    // task is a multi work time task
    // mark it as such
    // find the parent and extract its name and due date
    task.isMultiWorkTimeTask = true;
    const parentTask = currentTaskList.find(t => t.id === task.parentId);
    // for multiple work time tasks we need to inherit the name, due date, tags, priority and quickWin from the parent task
    if (!!parentTask) {
      task.name = parentTask.name;
      task.dueDate = parentTask.dueDate;
      task.tags = parentTask.tags?.length ? [...parentTask.tags] : [];
      task.priority = parentTask.priority;
      task.quickWin = parentTask.quickWin;
    }
  }
  else if (task.name && task.name?.length > 0 && task.parentId) {
    // task is a sub task of the parent task
    // get its siblings data for displaying in the UI (eg. 1 of 3)
    const siblingsOrderedByCreationTime = Array.from(new Map(currentTaskList.filter(t => t.parentId === task.parentId && t.name).map((item:IMessageDataTask)  => [item.id, item])).values()).sort((a, b) => new Date(a.creationTime).getTime() - new Date(b.creationTime).getTime());
    const subTaskOrder = siblingsOrderedByCreationTime.findIndex(s => s.id === task.id) + 1;
    if (siblingsOrderedByCreationTime.length > 0) task.siblingsData = { totalSiblings: siblingsOrderedByCreationTime.length, subTaskOrder };
  }
  task.isFullDayEvent = isFullDayEvent(task.duration);
  return task;
}

export const isFullDayEvent = (itemDuration?: number | null) => {
  if (itemDuration && itemDuration >= fullDayDurationInSeconds) return true;
  return false;
}

export const expandRecurringTask = (item: IMessageDataTask) => {
  const itemRecurrenceDates = item.workTimeRecurrenceDates;
  if (itemRecurrenceDates && itemRecurrenceDates.length > 0) {
    return itemRecurrenceDates.map((date, index) => {
      const starTimeProperty = {workTime: date}
      return {
        ...item,
        ...starTimeProperty,
        isRecurrenceInstance: index > 0 // the first recurrence belongs to the work time of the main task
      }
    });
  }
  return [item];
}

export const getTasksListReqAction = createApiThunk(createReducerKey('getTasksListReqAction'), () =>
  apiServiceCSharp.get<IStageTasksListResponse>(`${API_ROUTES.TASKS.TASKS_LIST}?timestamp=${store.getState().StageTasksReducer.tasksListResponse?.data?.userTaskTimestampForDelta || 0}`),
);

export const updateTaskReqAction = createApiThunk(createReducerKey('updateTaskReqAction'), (reqPayload?:ITaskUpdateReqPayload) => {
  localStorage.setItem(didUserCreateOrEditTaskStorageKey, JSON.stringify(true)); // For tracking task update for the displaying survey logic
  return apiServiceCSharp.patch<IMessageDataTask>(`${API_ROUTES.TASKS.UPDATE_TASK}`,reqPayload);
}
);

export const createTaskReqAction = createApiThunk(createReducerKey('createTaskReqAction'), (reqPayload?:ICreateTaskReqPayload) => {
  localStorage.setItem(didUserCreateOrEditTaskStorageKey, JSON.stringify(true));// For tracking task create for the displaying survey logic
  return apiServiceCSharp.post<IMessageDataTask[]>(`${API_ROUTES.TASKS.CREATE_TASK}`,reqPayload);
});

export const setTasksFilter = createAction<ETasksGroupsType>(createReducerKey('setTasksFilter'));

export const setShouldStageExpand = createAction<boolean>(createReducerKey('setShouldStageExpand'));

export const setSelectedMainTaskForEditing = createAction<IMessageDataTask | null>(createReducerKey('setSelectedMainTaskForEditing'));

export const setShouldOpenAddEditTaskFrom = createAction<ETaskFormType | null>(createReducerKey('setShouldOpenAddEditTaskFrom'));

export const setShouldStartDestroyAnimationTaskForm = createAction<boolean>(createReducerKey('setShouldStartDestroyAnimationTaskForm'));

export const setActiveMyPlanDay = createAction<string | null>(createReducerKey('setActiveMyPlanDay'));

export const setCalendarSelectedWorkTime = createAction<string | null>(createReducerKey('setCalendarSelectedWorkTime'));

export const setUpdatedTasksViaTheChat = createAction<IMessageDataTask[] | null>(createReducerKey('setUpdatedTasksViaTheChat'));

export const StageTasksReducer = createReducer(initialStateStageTasksStore, (builder) => {
  createAPIReducerCases(getTasksListReqAction, 'tasksListResponse', builder, {
    onFulfilled(state, tasksData) {
      // use new Map and .map() to merge the existing arrays with the newest without including duplicate items, based on the id property
      const mergedWithoutDuplicatesTasksArr =  Array.from(new Map((Object.values(state.tasksOrderedByGroups||{}).flat() as IMessageDataTask[]).concat(tasksData?.tasks || []).map((item:IMessageDataTask)  => [item.id, item])).values());
      const updatedTaskNamesWithoutDuplicates = mergedWithoutDuplicatesTasksArr.map(task => updateSubTaskNameAndSiblingsData(task, [...mergedWithoutDuplicatesTasksArr]));
      state.allTasks = [...updatedTaskNamesWithoutDuplicates.filter(t => !(t.isEvent && t.isWorkBlock)).map(task => expandRecurringTask(task)).flat()];
      state.allWorkBlocks = [...updatedTaskNamesWithoutDuplicates.filter(t => t.isEvent && t.isWorkBlock).map(task => expandRecurringTask(task)).flat()]
      const tasksOrderedByGroups = orderTasksListByGroups(mergedWithoutDuplicatesTasksArr);
      state.tasksOrderedByGroups = {...tasksOrderedByGroups};
    },
  });

  createAPIReducerCases(updateTaskReqAction, 'updateTaskRes', builder, {
    onFulfilled(state, updatedTask) {
      const task = updateSubTaskNameAndSiblingsData(updatedTask, [...state.allTasks]);
      state.updateTaskRes.data = {...task};
    }
  });
  
  createAPIReducerCases(createTaskReqAction, 'createTaskRes', builder, {
    onFulfilled(state, createdTasks) {
      const tasks = createdTasks.map(task => updateSubTaskNameAndSiblingsData(task, [...state.allTasks]));
      state.createTaskRes.data = [...tasks];
    }
  });

  builder.addCase(setTasksFilter, (state, action) => {
    const filterType = action.payload;
    if (filterType === ETasksGroupsType.MY_PLAN || filterType === ETasksGroupsType.MY_DAY || filterType === ETasksGroupsType.MY_WEEK) {
      localStorage.setItem(selectedFilterTypeLocalStorageKey, filterType);
    }
    state.tasksFilter = action.payload;
  });

  builder.addCase(setShouldStageExpand, (state, action) => {
    state.shouldStageExpand = action.payload;
  });
  
  builder.addCase(setSelectedMainTaskForEditing, (state, action) => {
    state.selectedMainTaskForEditing = action.payload;
  });

  builder.addCase(setShouldOpenAddEditTaskFrom, (state, action) => {
    state.shouldOpenAddEditTaskFrom = action.payload;
  });

  builder.addCase(setShouldStartDestroyAnimationTaskForm, (state, action) => {
    state.shouldStartDestroyAnimationTaskForm = action.payload;
  });

  builder.addCase(setActiveMyPlanDay, (state, action) => {
    state.activeMyPlanDay = action.payload;
  });

  builder.addCase(setCalendarSelectedWorkTime, (state, action) => {
    state.calendarSelectedWorkTime = action.payload;
  });

  builder.addCase(setUpdatedTasksViaTheChat, (state, action) => {
    state.updatedTasksViaTheChat = action.payload;
  });
});

