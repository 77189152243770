import { useCallback, useEffect, useRef } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { createCssVhVariableByViewportHeight } from "../../utils/createCssVhVariableByViewportHeight";
import { bodyInputFocusedClassName, chatInputId, isChatInputFocusedLocalStorageKey, plannerModeOnLocalStorageKey, selectedFilterTypeLocalStorageKey, stageParam } from "../../../app/constants";
import { setItemInSessionOrLocalStorage } from "../../utils/getItemFromSessionOrLocalStorage";
import { useAppBackgroundByTimeOfDay } from "../../hooks/useAppBackgroundByTimeOfDay";
import { ApplicationInsightsApi, ELogLabels } from "../../../application-insights";
import { EAppStageContentType } from "../../../features/chat-wrapper/resizable-container/stage-container/stageContainer.interfaces";
import { store, useAppDispatch } from "../../../app/store";
import { setTasksFilter } from "../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store";
import { ETasksGroupsType } from "../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface";
import { isMobileDevice } from "../../utils/isMobileDevice";
import { activePlannerUnscheduledTasksPlacer, resetPlannerState } from "../../../features/chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.utils";

const AppEventListenerHandler = () => {

  const location = useLocation();
  const params = useParams();
  const [searchParams,] = useSearchParams();
  const addToBodyFocusClassNameTimer = useRef<NodeJS.Timeout | null>(null);
  const scrollTopTouchendTimer = useRef<NodeJS.Timeout | null>(null);
  const lastKnownInputEventRef = useRef<string | null>(null);
  const bodyInputUnFocusedClassName = "keyboard-close";
  const appBackground = useAppBackgroundByTimeOfDay();
  const dispatch = useAppDispatch();
  const focusedInputElRef = useRef<HTMLElement | null>(null);
  const prevViewportHeightRef = useRef<number | null>(window.innerHeight);

  // 2 different function each event for different timers -> cant pass the timer as an argument because that we should to pass the reference of the function and cant pass an argument
  const handleFocusEventOnInputOrTextBox = useCallback((e: Event) => {
    handleBlurAndFocus('focus', e.target as HTMLElement);
  }, [])
  
  const handleBlurEventOnInputOrTextBox = useCallback((e: Event) => {
    handleBlurAndFocus('blur', e.target as HTMLElement);
  }, [])

  const triggerBlurEventOnFocusedInput = () => {
    const newViewportHeight = window.innerHeight;
    if(prevViewportHeightRef.current && prevViewportHeightRef.current < newViewportHeight && !!focusedInputElRef.current){
      focusedInputElRef.current.blur();
    }
    prevViewportHeightRef.current = newViewportHeight;
  }
  
  const handleBlurAndFocus = (eventType: 'focus' | 'blur', element: HTMLElement) => {
    if (addToBodyFocusClassNameTimer.current) clearTimeout(addToBodyFocusClassNameTimer.current);
    addToBodyFocusClassNameTimer.current = setTimeout(() => {
      if (lastKnownInputEventRef.current !== eventType) {
        if (eventType === "focus") {
          document.body.classList.remove(bodyInputUnFocusedClassName);
          if (!document.body.className.includes(bodyInputFocusedClassName)) document.body.classList.add(bodyInputFocusedClassName);
          if(element?.id === chatInputId) setItemInSessionOrLocalStorage<boolean>(isChatInputFocusedLocalStorageKey, true);
          // scroll into the focused element
          let scrollDirection: ScrollLogicalPosition = 'center';
          if (!!document.querySelector(".toast-messages-container") && !document.querySelector(".dislike-feedback-container")) scrollDirection = 'start';
          !!element && element?.scrollIntoView({ behavior: 'smooth', block: scrollDirection });
          focusedInputElRef.current = element;
        } 
        else {
          if(!document.hidden || isMobileDevice()) {
            element.blur();
            document.body.classList.remove(bodyInputFocusedClassName);
            document.body.classList.add(bodyInputUnFocusedClassName);
            setItemInSessionOrLocalStorage<boolean>(isChatInputFocusedLocalStorageKey, false);
            focusedInputElRef.current = null;
          }
        }
      }
      lastKnownInputEventRef.current = eventType;
    }, 300)
  }

  const windowScrollTopTouchend = useCallback(() => {
    if (scrollTopTouchendTimer.current) clearTimeout(scrollTopTouchendTimer.current);
    scrollTopTouchendTimer.current = setTimeout(() => {
      if (document.body.classList.contains(bodyInputFocusedClassName) || !!document.querySelector(".app-overlay-popover-content-container")) {
        window.scrollTo({
          top: 0,
        });
      }
    }, 400)
  }, []);

  const onChangeEventHandler = useCallback((e: any) => {
    try {
      const target = e.target;
      if (target?.nodeName === 'SELECT') {
        // the the option name
        const selectedOption = (target as any)?.options[target.value]?.text || "unable to extract option name";
        ApplicationInsightsApi.trackEvent(ELogLabels.SELECT, { selectedOption });
      }
    } catch (error) {
      ApplicationInsightsApi.trackException(error);
    }
  }, []);

  const removeOnchangeEventHandler = () => {
    document.removeEventListener('change', onChangeEventHandler);
  };

  // when the virtual keyboard is opened scroll to top the window
  const addEventFocusOnTextBox = useCallback((textBoxArr: (HTMLInputElement | HTMLTextAreaElement)[]) => {
    textBoxArr.forEach((item) => {
      item.addEventListener('focus', handleFocusEventOnInputOrTextBox);
      item.addEventListener('blur', handleBlurEventOnInputOrTextBox);
    })
  }, [handleFocusEventOnInputOrTextBox, handleBlurEventOnInputOrTextBox]);

  const removeEventFocusFromTextBox = useCallback((textBoxArr: (HTMLInputElement | HTMLTextAreaElement)[]) => {
    textBoxArr.forEach((item) => {
      item.removeEventListener('focus', handleFocusEventOnInputOrTextBox);
      item.removeEventListener('blur', handleBlurEventOnInputOrTextBox);
    })
  }, [handleFocusEventOnInputOrTextBox, handleBlurEventOnInputOrTextBox]);


  useEffect(() => {
    const myInterval = setInterval(() => {

      const nodes = document.querySelectorAll(`input[type=text], input[type=number], textarea`) as unknown as (HTMLInputElement | HTMLTextAreaElement)[];
      if (nodes.length > 0 && !(window as any).isAxiosPending) {
        addAppEventListener(nodes);
        clearInterval(myInterval);
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addAppEventListener = useCallback((textboxElements: (HTMLInputElement | HTMLTextAreaElement)[]) => {
    addEventFocusOnTextBox(textboxElements);

    document.addEventListener('touchend', windowScrollTopTouchend);
    document.addEventListener('change', onChangeEventHandler);
  }, [addEventFocusOnTextBox, windowScrollTopTouchend, onChangeEventHandler])

  useEffect(() => {
    const keyboardTriggeredArr = document.querySelectorAll(`input[type=text], input[type=number], input[type=tel], input[type=email], textarea, [contenteditable=true]`) as unknown as (HTMLInputElement | HTMLTextAreaElement)[];
    addAppEventListener(keyboardTriggeredArr);
    return () => {
      if (addToBodyFocusClassNameTimer.current) clearTimeout(addToBodyFocusClassNameTimer.current);
      if (scrollTopTouchendTimer.current) clearTimeout(scrollTopTouchendTimer.current);
      removeEventFocusFromTextBox(keyboardTriggeredArr);
      document.removeEventListener('touchend', windowScrollTopTouchend);
      // reset lastKnownInputEventRef and body className for animation
      lastKnownInputEventRef.current = null;
      document.body.classList.remove(bodyInputFocusedClassName);
      document.body.classList.remove(bodyInputUnFocusedClassName);
      setItemInSessionOrLocalStorage<boolean>(isChatInputFocusedLocalStorageKey, false);
    }

  }, [addEventFocusOnTextBox, location, params, removeEventFocusFromTextBox, searchParams, windowScrollTopTouchend, addAppEventListener])

  // responsible for managing changes in the stage filter dropdown while moving between tasks and planner stages
  useEffect(() => {
      const stageContent = searchParams.get(stageParam) as EAppStageContentType;
      const currentSelectedFilter = store.getState().StageTasksReducer.tasksFilter as ETasksGroupsType;
      switch (stageContent) {
        case EAppStageContentType.TASKS:
          if ([ETasksGroupsType.MY_DAY, ETasksGroupsType.MY_PLAN, ETasksGroupsType.MY_WEEK].includes(currentSelectedFilter)) dispatch(setTasksFilter(ETasksGroupsType.MY_TASKS));
          break;
        case EAppStageContentType.PLANNER:
          if (plannerModeOnLocalStorageKey in localStorage) {
            activePlannerUnscheduledTasksPlacer();
          }
          if (![ETasksGroupsType.MY_DAY, ETasksGroupsType.MY_PLAN, ETasksGroupsType.MY_WEEK].includes(currentSelectedFilter)) dispatch(setTasksFilter(localStorage.getItem(selectedFilterTypeLocalStorageKey) as ETasksGroupsType || ETasksGroupsType.MY_PLAN));
          break;
        default:
          resetPlannerState();
          break;
      }
  }, [searchParams, dispatch]);

  useEffect(() => {
    createCssVhVariableByViewportHeight();
    addWindowResizeEvent();
    return () => {
      removeWindowResizeEvent();
      removeOnchangeEventHandler();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addWindowResizeEvent = () => {
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', createCssVhVariableByViewportHeight);
    } else {
      window.addEventListener('resize', createCssVhVariableByViewportHeight);
    }
  };

  const removeWindowResizeEvent = () => {
    if (window.visualViewport) {
      window.visualViewport.removeEventListener('resize', createCssVhVariableByViewportHeight);
    } else {
      window.removeEventListener('resize', createCssVhVariableByViewportHeight);
    }
  };

  // set the app background
  useEffect(() => {
    document.documentElement.style.setProperty('--appDesktopBackground', appBackground.desktopBG);
    document.documentElement.style.setProperty('--appMobileBackground', appBackground.mobileBG);
  }, [appBackground]);

  // trigger the blur event on the focused input when the window is resized while the keyboard is closing by the keyboard closing button on mobile devices
  useEffect(() => {
    if (isMobileDevice()) {
      const handleResize = () => {
        triggerBlurEventOnFocusedInput();
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      }
    }
  }, [dispatch]);

  return <></>;
}
export default AppEventListenerHandler;