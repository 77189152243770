import { Link } from "react-router-dom"
import { ContentFrameWrapper } from "../../../../shared/components/content-frame-wrapper/ContentFrameWrapper"
import { APP_ROUTING_PATHS, EUserAgeSlice, ageGateLocalStorageKey } from "../../../constants"
import { useTranslation } from "react-i18next";
import { AppSelect } from "../../../../shared/components/app-select/AppSelect";
import { TermsOfService } from "../terms-of-service/TermsOfService";
import { useForm } from "react-hook-form";
import './AgeGate.scss';
import { setItemInSessionOrLocalStorage } from "../../../../shared/utils/getItemFromSessionOrLocalStorage";
import { useNavigateWithSearchParams } from "../../../../shared/utils/routingUtils";

class AgeGateForm {
    age: number | undefined = undefined;
    isParentsConfirmed: boolean = false;
}

export const AgeGate = () => {
    const { t } = useTranslation();
    const navigate = useNavigateWithSearchParams();

    const { register, formState, reset, setValue, watch, getValues } = useForm<AgeGateForm>({
        defaultValues: new AgeGateForm()
    });
    
    const onSubmit = () => {
        const ageSlice = getValues('age');
        setItemInSessionOrLocalStorage(ageGateLocalStorageKey, ageSlice, true);
    }

    return (
        <div className="age-gate-page auth-screen">
            <ContentFrameWrapper>
                <div className="age-gate-content-container">
                    <form id="age-gate-form" className="age-gate-form">
                        <div className="age-gate-form-content">
                            <h1 className='age-gate-title auth-title'>{t('registrationScreenTitle')}</h1>
                            <p className='age-gate-paragraph'>{t("ageGateBeforeStart")}</p>
                            <p className="age-gate-paragraph">{t("ageGateSelectYourAge")}</p>
                            <AppSelect className="age-gate-select" options={[
                                { value: EUserAgeSlice.MINOR, label: t("ageGateSelectMinor") },
                                { value: EUserAgeSlice.TEENAGER, label: t("ageGateSelectTeenager") },
                                { value: EUserAgeSlice.ADULT, label: t("ageGateSelectAdult") }
                            ]} onChange={e => Number(e.target.value) ? setValue('age', Number(e.target.value), { shouldValidate: true }) : reset(new AgeGateForm())} value={watch('age') || 0} />
                            {watch('age') === EUserAgeSlice.TEENAGER && <div className='registration-checkbox-container'>
                                <input type="checkbox" id="age-gate-parent-confirmation" {...register('isParentsConfirmed', { required: true })} />
                                <label htmlFor="age-gate-parent-confirmation">{t("ageGateParentConfirmation")}</label>
                            </div>}
                            {watch('age') === EUserAgeSlice.MINOR && <p className="age-gate-paragraph">{t("ageGateMinorText")}</p>}
                        </div>
                        {watch('age')! > EUserAgeSlice.MINOR && <button className='auth-next-btn' form="age-gate-form" id="age-gate-form-submit" type='button' onClick={onSubmit} disabled={!formState.isValid || formState.isSubmitted} >{t('registerScreenNextButtonText')}</button>}
                    </form>
                    <TermsOfService />
                </div>
            </ContentFrameWrapper>
            <Link to={APP_ROUTING_PATHS.MORE_INFO_LAST_SCREEN} onClick={() => navigate(-1)} className='back-link back-link-mobile'>{t('registerScreenBackButtonText')}</Link>
        </div>
    )
}