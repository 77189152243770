import { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { setTasksFilter } from '../stageTasks.store';
import { ETasksGroupsType } from '../stageTasks.interface';
import arrowDownIcon from '../../../../../../assets/images/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDownSvgComponent } from "../../../../../../assets/images/dropdown-filter-arrow-down.svg"
import './StageTasksDropdownFilters.scss';
import { getTasksGroupEmoji } from '../../../../chat/chat-conversation/message-data-card/tasks-list/TasksList.utils';
import { EAppStageContentType } from '../../stageContainer.interfaces';
import { useSearchParams } from 'react-router-dom';
import { stageParam } from '../../../../../../app/constants';
import AppButton from '../../../../../../shared/components/app-button/AppButton';

const filterOptions = [
  { filters: [ETasksGroupsType.MY_TASKS, ETasksGroupsType.QUICK_WINS, ETasksGroupsType.UNSCHEDULED, ETasksGroupsType.OVERDUE, ETasksGroupsType.DONE], viewIn: EAppStageContentType.TASKS},
  { filters: [ETasksGroupsType.MY_PLAN, ETasksGroupsType.MY_DAY, ETasksGroupsType.MY_WEEK], viewIn: EAppStageContentType.PLANNER}
]

interface IProps {
  filters?: ETasksGroupsType[];
}

export const StageTasksDropdownFilters = ({filters}: IProps) => {
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const { tasksFilter } = useAppSelector(store => store.StageTasksReducer);
  const filterOptionsArr: ETasksGroupsType[] = filters || filterOptions.find(item => item.viewIn === searchParams.get(stageParam))?.filters || [];
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = () => {
      // if click at the document and the dropDown is open, close it
      if (dropdownRef.current && dropdownRef.current.clientHeight > 0) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleFilter = (filter: ETasksGroupsType) => {
    dispatch(setTasksFilter(filter));
  }

  // const handleBlur = () => {
  //   if (isOpen) {
  //     setTimeout(() => {
  //       setIsOpen(false);
  //     }, 20);
  //   }
  // }

  return (
    <div className={`stage-tasks-dropdown-filter ${isOpen ? 'open' : ''} stage-tasks-dropdown-filter--${searchParams.get(stageParam)}`}>
      <AppButton
        id="open-stage-filter-dropdown-menu"
        type='button'
        className="dropdown-toggle"
        // onBlur={handleBlur}
        onClick={() => setIsOpen(!isOpen)}>
        <span>
          {getTasksGroupEmoji(tasksFilter)}
          {tasksFilter}
        </span>
        <ArrowDownSvgComponent className="dropdown-toggle--arrow-down" />
      </AppButton>
      <div className={`dropdown-menu`} ref={dropdownRef}>
        <div className='dropdown-view'>{t('stageTasksDropDownTitle')} <img src={arrowDownIcon} alt="arrow-down" /></div>
        {filterOptionsArr.map(filterItem => (
          <AppButton id={`${filterItem?.trim()}-filter-stage-dropdown-menu`} key={filterItem} className={`dropdown-item ${filterItem === ETasksGroupsType.MY_TASKS && 'my-tasks'}`} onClick={() => { handleFilter(filterItem) }}>
            {getTasksGroupEmoji(filterItem)}{filterItem}
          </AppButton>
        ))}
      </div>
    </div>
  )
}