import React, {FunctionComponent,ReactNode} from 'react';
import expand from '../../../../../assets/images/expand.svg';
import { useAppDispatch } from '../../../../../app/store';
import { setShouldStageExpand } from '../stage-tasks/stageTasks.store';
import './StageHeader.scss';
import { useSearchParams } from 'react-router-dom';
import { chatHistoryParam, dislikeFeedbackParam } from '../../../../../app/constants';
import AppButton from '../../../../../shared/components/app-button/AppButton';
 
export const StageHeader:FunctionComponent<{children?: ReactNode|ReactNode[]}>= ({children}) => {
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();

  const expandStage = () => {
    dispatch(setShouldStageExpand(true));
    // close dislike-feedback and chat-history modals while stage expanding
    setSearchParams(prev => {
      prev.delete(chatHistoryParam);
      prev.delete(dislikeFeedbackParam);
      return prev;
    });
  }

  return (
    <div className='stage-header'>
      <AppButton id="expand-stage" type="button" className='expand' onClick={expandStage}><img src={expand} alt="expand" /></AppButton>
      <div className="stage-header-content">
      {children}
      </div>
    </div>
  )
};