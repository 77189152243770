import { shouldStayLoggedInLocalStorageKey } from "../../app/constants";
import { ApplicationInsightsApi } from "../../application-insights";

export function getItemFromSessionOrLocalStorage<T>(key: string): T | null {
  try {
      const localStorageValue = localStorage.getItem(key);
      if (localStorageValue) return JSON.parse(localStorageValue);
      const sessionStorageValue = sessionStorage.getItem(key);
      if (sessionStorageValue) return JSON.parse(sessionStorageValue);
      return null;
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return null;
  }
}

export function setItemInSessionOrLocalStorage<T>(key: string, value: T, shouldSaveInLocalStorage: boolean = false): void {
  try {
    if (isNoneBrowserEnvironment()) return;
    if (shouldStayLoggedIn() || shouldSaveInLocalStorage) localStorage.setItem(key, JSON.stringify(value));
    else sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
  }
}

export function removeItemFromSessionOrLocalStorage(key: string, shouldRemoveFromLocalStorage: boolean = false): void {
  try {
    if (isNoneBrowserEnvironment()) return;
    if (shouldStayLoggedIn() || shouldRemoveFromLocalStorage) window.localStorage.removeItem(key);
    else window.sessionStorage.removeItem(key);
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
  }
}


export function isNoneBrowserEnvironment(): boolean {
  return typeof window === 'undefined';
}

// TODO remove this function when keep me signed in feature is removed
export function shouldStayLoggedIn(): boolean {
  const stayLoggedInStoredValue = localStorage.getItem(shouldStayLoggedInLocalStorageKey);
  return stayLoggedInStoredValue ? JSON.parse(stayLoggedInStoredValue) : true;
}