import { useTranslation } from 'react-i18next';
import { ContentFrameWrapper } from '../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { APP_ROUTING_PATHS, isSignUpLocalStorageKey, registrationParametersStorageKey, shouldStayLoggedInLocalStorageKey, userInfoLocalStorageKey } from '../../constants';
import { IUser } from '../auth.interfaces';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { ApplicationInsightsApi } from '../../../application-insights';
import { moveDataFromSessionToLocalStorage } from '../../../shared/utils/moveDataFromSessionToLocalStorage';
import { handleLogOut } from '../../../shared/utils/logOut';
import './StayLoggedIn.scss';
import { useApiData } from '../../../shared/hooks/useApiData';
import { useAppDispatch, useAppSelector } from '../../store';
import { getItemFromSessionOrLocalStorage } from '../../../shared/utils/getItemFromSessionOrLocalStorage';
import { registrationReqAction } from '../auth.store';
import { setIsComeFromRegistration } from '../../../shared/store/shared.store';
import stayLoggedInMascot from '../../../assets/images/mascot/stay-logged-in-mascot.png';
import { useNavigateWithSearchParams } from '../../../shared/utils/routingUtils';
import AppButton from '../../../shared/components/app-button/AppButton';

export const StayLoggedIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearchParams();
  const [isStayLoggedInChecked, setIsStayLoggedInChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { registrationRes } = useAppSelector(store => store.authReducer);
  const dispatch = useAppDispatch();
  const isComeFromSignUp = !!getItemFromSessionOrLocalStorage(isSignUpLocalStorageKey);

  const onClickNext = () => {
    try {
      setIsLoading(true);
      localStorage.setItem(shouldStayLoggedInLocalStorageKey, JSON.stringify(isStayLoggedInChecked))
      if (isStayLoggedInChecked) moveDataFromSessionToLocalStorage();
      navigateToTheNextPage();
    }
    catch (error) {
      setIsLoading(false);
      ApplicationInsightsApi.trackException(error);
    }
  }

  const navigateToTheNextPage = () => {
    const user = getItemFromSessionOrLocalStorage<IUser>(userInfoLocalStorageKey);
    if (!user?.isActive) {
        dispatch(registrationReqAction({
          registrationParameters: localStorage.getItem(registrationParametersStorageKey) || null
        }));
        dispatch(setIsComeFromRegistration(true));
    }
    else navigate(APP_ROUTING_PATHS.REDIRECT);
  }

  useApiData(registrationRes, {
    onRejected: () => {
      setIsLoading(false);
    }
  })

  return (
    <div className="stay-logged-in-page auth-screen">
      <ContentFrameWrapper className='stay-logged-in-frame'>
        <h1 className='auth-title' data-testid="stay-logged-in-title">{t("stayLoggedInPageTitle")}</h1>
        <img className='stay-logged-in-mascot' src={stayLoggedInMascot} alt="mascot" />
        <div className="registration-checkbox-container">
          <label>
            <input type="checkbox" name="stay-logged-in-checkbox" data-testid="stay-logged-in-checkbox" onChange={(e) => setIsStayLoggedInChecked(e.target.checked)} />
            {t("stayLoggedInPageCheckboxLabelText")}
          </label>
          <span>{t("stayLoggedInPageCheckboxWarningText")}</span>
        </div>
        <div className="buttons-container">
          <AppButton type='button' id="stay-logged-in-next" className='auth-next-btn' onClick={onClickNext} disabled={isLoading} data-testid="stay-logged-in-next">{t("stayLoggedInPageConfirmButtonText")}</AppButton>
        </div>
      </ContentFrameWrapper>
      <Link to={isComeFromSignUp ? APP_ROUTING_PATHS.SIGN_UP : APP_ROUTING_PATHS.SIGN_IN} data-testid="stay-logged-in-back" onClick={() => handleLogOut(false)} className='back-link'>{t('registerScreenBackButtonText')}</Link>
    </div>
  )
}