import React, { useEffect, useState } from "react";
import { store, useAppDispatch, useAppSelector } from "../../../app/store";
import { isMobileDevice } from "../../../shared/utils/isMobileDevice";
import { isDesktopView, isMobileView } from "../../../shared/utils/utils";
import { EPlannerMode, setPlannerCurrentTaskPlacement } from "../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store";
import './PlanTasksPlacementList.scss';
import { resetPlannerState } from "../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.utils";
import { ITaskUpdateReqPayload } from "../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface";
import SingleTaskItemContainer, { ETaskViewType } from "../../chat-wrapper/chat/chat-conversation/message-data-card/tasks-list/tasks-list-single-item/SingleTaskItemContainer";
import { useTranslation } from "react-i18next";
import closeIcon from '../../../assets/images/close-icon-white-big.svg';
import closeIconMobile from '../../../assets/images/close-icon-grey.svg';
import backIcon from '../../../assets/images/back-icon.svg';
import emptyStateImg from '../../../assets/images/mascot/mascot-chart.png';
import createAppOverlayPopover from "../../../shared/components/app-overlay-popover/createAppOverlayPopover";
import SassVariables from '../../../styles/style.module.scss';
import removeAppOverlayPopover from "../../../shared/components/app-overlay-popover/removeAppOverlayPopover";
import { TimePickerContext } from "../../chat-wrapper/resizable-container/stage-container/stage-tasks/task-edit-overlays/time-picker-context/TimePickerContext";
import { getDateWithoutYear, getShortDateWithDuration } from "../../../shared/utils/dateFormat";
import { getItemFromSessionOrLocalStorage } from "../../../shared/utils/getItemFromSessionOrLocalStorage";
import { chatSessionIdSessionStorageKey, timePickerContextOverlayDefaultWidth } from "../../../app/constants";
import { getTasksListReqAction, updateTaskReqAction } from "../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store";
import { EAPIStatus } from "../../../shared/api/models";
import { PlanTaskPlacementSetButton } from "./PlanTaskPlacementSetButton";
import { filterForUnscheduledTasks } from "../plan.utils";
import AppButton from "../../../shared/components/app-button/AppButton";
import AppEmptyState from "../../../shared/components/app-empty-state/AppEmptyState";

enum EPlanTasksPlacementListMode {
    FULL = 'full',
    CLOSED = 'closed',
    PLACING = 'placing',
}

export const PlanTasksPlacementList = () => {
    const mainLayoutClass = 'plan-tasks-placement-list-container';
    const devicePrefix = isMobileDevice() || isMobileView() ? 'mobile' : 'desktop';
    const deviceClass = `${mainLayoutClass}--${devicePrefix}`;
    const { t } = useTranslation();
    const { plannerMode, currentTaskPlacement } = useAppSelector(store => store.StagePlannerReducer);
    const { allTasks, updateTaskRes, tasksListResponse } = useAppSelector(store => store.StageTasksReducer);
    const [mode, setMode] = useState<EPlanTasksPlacementListMode | null>(EPlanTasksPlacementListMode.CLOSED);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setMode(plannerMode === EPlannerMode.UNSCHEDULEDTASKSPLACER ? EPlanTasksPlacementListMode.FULL : EPlanTasksPlacementListMode.CLOSED);
    }, [plannerMode]);

    const getSelectedTimeStrToDisplay = () => {
        if (!!currentTaskPlacement?.workBlockId && !!currentTaskPlacement?.workBlockStartTime) {
          return `${currentTaskPlacement.workBlockName || t("workBlockDefaultName")} ${getDateWithoutYear(currentTaskPlacement?.workBlockStartTime)}`;
        } else if (currentTaskPlacement?.workTime) {
          return `${getShortDateWithDuration(currentTaskPlacement?.workTime, currentTaskPlacement?.duration)}`;
        }
        return '';
      }

    useEffect(() => {
        if (plannerMode === EPlannerMode.UNSCHEDULEDTASKSPLACER) {
            if (currentTaskPlacement) {
                setMode(EPlanTasksPlacementListMode.PLACING);
                if (isDesktopView() && !isMobileDevice()) {
                    createAppOverlayPopover(
                        <TimePickerContext
                            taskName={currentTaskPlacement?.name || ''}
                            selectedTimeStr={getSelectedTimeStrToDisplay()}
                            onClose={() => {
                                removeAppOverlayPopover();
                                dispatch(setPlannerCurrentTaskPlacement(null));
                            }}
                            onSet={<PlanTaskPlacementSetButton onSet={onSetUnscheduledWorkTime} />}
                        />,
                        null,
                        null,
                        {
                            bottom: `calc(${SassVariables.overlayDesktopBottomHeight} - 20px)`,
                            left: "unset",
                            right: 50,
                            width: `${timePickerContextOverlayDefaultWidth}px`,
                        },
                        {
                            isCustomStyle: true, allowInteraction: true, closeOnClickOutside: false
                        }
    
                    )
                }
            } else {
                if (isDesktopView() && !isMobileDevice()) {
                    removeAppOverlayPopover();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTaskPlacement]);

    const onClose = () => {
        resetPlannerState(true);
        removeAppOverlayPopover();
    }

    const handleBackToList = () => {
        dispatch(setPlannerCurrentTaskPlacement(null));
        setMode(EPlanTasksPlacementListMode.FULL);

    }

    const onSetUnscheduledWorkTime = () => {
        if (tasksListResponse.status === EAPIStatus.PENDING || updateTaskRes.status === EAPIStatus.PENDING) return;
        const placedTask = store.getState().StagePlannerReducer.currentTaskPlacement;
        const payload: ITaskUpdateReqPayload = {
            sessionId: getItemFromSessionOrLocalStorage(chatSessionIdSessionStorageKey) || '',
            id: placedTask?.id || '',
            workTime: placedTask?.workBlockId ? null : new Date(placedTask?.workTime!).toISOString(),
            workBlockId: placedTask?.workBlockId || null,
            workBlockInstance: placedTask?.workBlockInstance || null,
            duration: placedTask?.duration || null,
        }
        dispatch(updateTaskReqAction(payload)).then(() => {
            dispatch(getTasksListReqAction()).then(() => {
                handleBackToList();
            });
        });
    }

    const currentTaskContext = () => {
        return (
            <div className="plan-tasks-placement-current-task-container">
                <h1 className="plan-tasks-placement-current-task-name">{currentTaskPlacement?.name}</h1>
                <p className="plan-tasks-placement-current-task-name">{getSelectedTimeStrToDisplay()}</p>
            </div>
        )
    }

    const getEmptyStateText = () => {
        if ((allTasks || []).filter(filterForUnscheduledTasks).length === 0) return null;
        return t(isMobileDevice() || isMobileView() ? 'tapScheduleMobile' : 'tapScheduleDesktop')
    }

    return (
        <div className={`${mainLayoutClass} ${deviceClass} ${mainLayoutClass}--${mode}`}>
            <div className="plan-tasks-placement-list-inner-container">
                <div className="plan-tasks-placement-list-actions-container">
                    <AppButton id="planner-mode-unscheduled-task-list-close-button" type="button" className={`plan-tasks-placement-list-close-button plan-tasks-placement-list-close-button--full no-style-button`} onClick={onClose}><img src={isMobileDevice() || isMobileView() ? closeIconMobile : closeIcon} alt={t('close')} /></AppButton>
                    <AppButton id="planner-mode-unscheduled-task-list-back-button" type="button" className={`plan-tasks-placement-list-close-button plan-tasks-placement-list-close-button--placing no-style-button`} onClick={handleBackToList}><img src={backIcon} alt={t('close')} /></AppButton>
                    <h3 className="plan-tasks-placement-header plan-tasks-placement-header--full static-string">{getEmptyStateText()}</h3>
                    <h3 className="plan-tasks-placement-header plan-tasks-placement-header--placing static-string">{currentTaskPlacement?.workTime ? t('myPlanDayScheduled') : t('pickTime')}</h3>
                </div>
                <div className="plan-tasks-list-scroll">
                    <div className="plan-tasks-list">
                        {
                            ((allTasks || [])).filter(filterForUnscheduledTasks).sort((ta,tb) => new Date(ta.creationTime).getTime() - new Date(tb.creationTime).getTime()).map((task, index) => <React.Fragment key={task.id}><SingleTaskItemContainer shouldHideOnComplete={false} viewType={ETaskViewType.CALENDAR_PLACEMENT} shouldDisplayActionButtons={false} shouldDisplayFirstTagColor={true} key={index} singleTask={task} /></React.Fragment>)
                        }
                    </div>
                </div>
                {currentTaskContext()}
                {!getEmptyStateText() && 
                    <AppEmptyState 
                        emptyStateImage={emptyStateImg} 
                        paragraph={t("plannerEmptyStateText")}
                        className="planner-empty-state" 
                    />
                }
            </div>
            {<PlanTaskPlacementSetButton onSet={onSetUnscheduledWorkTime} />}
        </div>
    )
}