import './StagePlanner.scss';
import { StageHeader } from '../stage-header/StageHeader';
import { MyPlan } from '../../../../plan/my-plan/MyPlan';
import { PlanScrollContainer } from '../../../../plan/plan-scroll-container/PlanScrollContainer';
import { StageTasksDropdownFilters } from '../stage-tasks/stage-tasks-dropdown-filters/StageTasksDropdownFilters';
import { ETasksGroupsType } from '../stage-tasks/stageTasks.interface';
import { MyDay } from '../../../../plan/my-day/MyDay';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { MyWeek } from '../../../../plan/my-week/MyWeek';
import CreatePlusButton from '../../../../plan/create-plus-button/CreatePlusButton';
import { PlanUnscheduledFab } from '../../../../plan/plan-unscheduled-fab/PlanUnscheduledFab';
import { PlanTasksPlacementList } from '../../../../plan/plan-tasks-list/PlanTasksPlacementList';
import { useEffect } from 'react';
import { uxEventEnterForm } from '../../../../../shared/utils/uxEventsUtils';
import { setQueueMessageData } from '../../../chat.store';
import { selectedFilterTypeLocalStorageKey } from '../../../../../app/constants';

export interface IPlannerProps {
  plannerDisplayType?: ETasksGroupsType | null;
}

export const StagePlanner = ({plannerDisplayType}: IPlannerProps) => {
  const { tasksFilter } = useAppSelector(store => store.StageTasksReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setQueueMessageData({ type: 'automatic', botRequestJson: JSON.stringify(uxEventEnterForm("Plan", localStorage.getItem(selectedFilterTypeLocalStorageKey) || ETasksGroupsType.MY_PLAN)) }));
  }, [dispatch]);


  return (
    <div className='stage-planner-container'>
      <StageHeader>
        <div className='stage-planner-header-container'>
            <StageTasksDropdownFilters filters={plannerDisplayType ? [ETasksGroupsType.MY_PLAN, ETasksGroupsType.MY_DAY, ETasksGroupsType.MY_WEEK] : undefined}/>
            <PlanUnscheduledFab />
        </div>
      </StageHeader>
      <div className='stage-planner-content-container'>
        <div className='stage-planner-content-planner-container'>
          <PlanScrollContainer 
          className={'plan-container-horizontal'} 
          scrollDirection={'x'}>
            {tasksFilter === ETasksGroupsType.MY_PLAN && <MyPlan />}
            {tasksFilter === ETasksGroupsType.MY_DAY && <MyDay />}
            {tasksFilter === ETasksGroupsType.MY_WEEK && <MyWeek />}
          </PlanScrollContainer>
          <CreatePlusButton />
        </div>
        <div className='stage-planner-content-unscheduled-tasks-container'>
            <PlanTasksPlacementList />
        </div>
      </div>
    </div>
  )
}