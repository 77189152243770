import { FunctionComponent } from 'react';
import { UserAvatarHeader } from './user-avatar/UserAvatarHeader';
import { ToggleHistoryHeaderIcon } from './toggle-history-icon/ToggleHistoryHeaderIcon';
import { StageTasksDropdownFilters } from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stage-tasks-dropdown-filters/StageTasksDropdownFilters';
import collapseIcon from '../../../../assets/images/collapse-icon.svg';
import { setShouldStageExpand } from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import { useAppDispatch } from '../../../../app/store';
import './Header.scss';
import ClearScratchpadButton from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-scratchpad/clear-scratchpad-button/ClearScratchpadButton';
import { PlanUnscheduledFab } from '../../../../features/plan/plan-unscheduled-fab/PlanUnscheduledFab';
import { useSearchParams } from 'react-router-dom';
import { stageParam } from '../../../../app/constants';

export const Header: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const displayPlannerFabOrAvatar = () => {
    if (searchParams.get(stageParam) === 'planner') {
      return <PlanUnscheduledFab />;
    }
    return <UserAvatarHeader />;
  }

  return (
    <header className="header-container">
      <button id="collapse-minimize-stage" type="button" className='minimize-stage' onClick={() => dispatch(setShouldStageExpand(false))}><img src={collapseIcon} alt="collapse" /></button>
      <div className='header-content'>
        <StageTasksDropdownFilters />
        <ClearScratchpadButton />
        <ToggleHistoryHeaderIcon />
        {displayPlannerFabOrAvatar()}
      </div>
    </header>
  )
}