import { useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { EAPIStatus } from '../../../../../shared/api/models';
import { transformResponseMessageToChatMessage } from '../../../chat/Chat.utils';
import MessageWrapper from '../../../chat/message/MessageWrapper';
import ErrorMessage from '../../../chat/message/error-message/ErrorMessage';
import closeImg from "../../../../../assets/images/close-toast.svg";
import { resetBotResponse } from '../../../chat.store';
import './ToastMessages.scss';
import messageLoadingAnimation from '../../../chat/MessageLoadingAnimation.json';
import Lottie from 'lottie-react';
import { isMobileView, setDataAttributeOnBody } from '../../../../../shared/utils/utils';
import { bodyInputFocusedClassName, dataAttributes, isChatInputFocusedLocalStorageKey } from '../../../../../app/constants';
import { getItemFromSessionOrLocalStorage } from '../../../../../shared/utils/getItemFromSessionOrLocalStorage';
import { IChatMessage } from '../../../chat.interfaces';
import { useApiData } from '../../../../../shared/hooks/useApiData';

const ToastMessages = () => {
  const { updateTaskRes, tasksListResponse } = useAppSelector(store => store.StageTasksReducer);
  const { botResponse } = useAppSelector(store => store.chatReducer);
  const [shouldDisplayMessage, setShouldDisplayMessage] = useState<'info' | 'error' | null>(null);
  const startFadeOutAnimationTimerRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const [shouldStartDestroyAnimation, setShouldStartDestroyAnimation] = useState(false);
  // After this time passes (30 seconds), the destroy animation (fade-out) will start.
  const timeInMilliSecondsToStartTheDestroyAnimation = 30000;
  const [toastMessage, setToastMessage] = useState<IChatMessage | null>(null);

  const clearTimer = () => {
    if (startFadeOutAnimationTimerRef.current) clearTimeout(startFadeOutAnimationTimerRef.current);
  }

  const startTimerForCloseAnimation = useCallback(() => {
    clearTimer();
    startFadeOutAnimationTimerRef.current = setTimeout(() => {
      setShouldStartDestroyAnimation(true);
    }, timeInMilliSecondsToStartTheDestroyAnimation);
  }, []);

  useEffect(() => {
    // display new toast only in desktop or in mobile when the keyboard is close or open on the chat bar field -to prevent from toast to appear while the keyboard is open on fields that is not the chat field in mobile
    const shouldDisplayNewToast = ((getItemFromSessionOrLocalStorage<boolean>(isChatInputFocusedLocalStorageKey) || !document.body.className.includes(bodyInputFocusedClassName)) && isMobileView()) || !isMobileView();
    if (shouldDisplayNewToast) {
      if (!botResponse.data?.length && [updateTaskRes.status, tasksListResponse.status, botResponse.status].includes(EAPIStatus.REJECTED)) {
        setShouldDisplayMessage('error');
        setDataAttributeOnBody(dataAttributes.chatToastMessageDataType, 'error');
        startTimerForCloseAnimation();
      }
    }
    return () => clearTimer();
  }, [botResponse.data, updateTaskRes.status, tasksListResponse.status, botResponse.status, startTimerForCloseAnimation]);

  useApiData(botResponse, {
    onFulfilled: (response) => {
      const shouldDisplayNewToast = ((getItemFromSessionOrLocalStorage<boolean>(isChatInputFocusedLocalStorageKey) || !document.body.className.includes(bodyInputFocusedClassName)) && isMobileView()) || !isMobileView();
      const hasValidMessage = !!response?.length && response[response.length - 1].party === 'Bot' && response[response.length - 1].text.length > 0;
      if (shouldDisplayNewToast && hasValidMessage) {
        setToastMessage(transformResponseMessageToChatMessage([botResponse.data![botResponse.data!.length - 1]], null)[0]);
        setShouldDisplayMessage('info');
        setDataAttributeOnBody(dataAttributes.chatToastMessageDataType, 'info');
        startTimerForCloseAnimation();
      }
    },
  });

  const onClose = () => {
    setShouldDisplayMessage(null);
    dispatch(resetBotResponse());
    setShouldStartDestroyAnimation(false);
  }

  return (
    <>
    {!!shouldDisplayMessage && <div
        className={`toast-messages-container ${shouldStartDestroyAnimation ? 'fadeOut' : ''}`}
        onAnimationEnd={(animationData) => {
          if (animationData.animationName === "fadeOutAnimation") onClose();
        }}>
        {botResponse.status === EAPIStatus.PENDING && <Lottie animationData={messageLoadingAnimation} loop={true} className='lottie-message-animation lottie-message-animation-toast' />}
        <div className='toast-messages-container-fade-container fadeIn'>
          {(shouldDisplayMessage === 'error' || (shouldDisplayMessage === 'info' && botResponse?.data && botResponse.data.length > 0)) && <>
            <button id="close-bot-toast" className='close-toast' onClick={onClose}>
              <img src={closeImg} alt="close" />
            </button>
            {toastMessage && botResponse?.data && botResponse.data.length > 0 && <MessageWrapper isToastMessage={true} msgItem={toastMessage} hideMsgTime={true} />}
            {shouldDisplayMessage === 'error' && <ErrorMessage className={`${botResponse.status === EAPIStatus.REJECTED ? 'bot-response-error' : ''}`} testId='stage-toast-reject-error' />}
          </>}
        </div>
      </div>}
    </>
  )
}

export default ToastMessages
